import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import ProfileGrid from "../components/ProfileGrid/ProfileGrid";
import { removeContactsWithNoImages } from "../scripts/helpers"
import { Helmet } from 'react-helmet'


// const onListChange = (newList, updateFunction, storeName, updateRoleSelectKeyFunction) => {
//   store.set(storeName, newList)
//   updateFunction(newList)

//   if (storeName === 'roles') {
//     if (getCurrentRolesInStore.length === 0) {
//       removeInvalidRolesFromContacts()
//       updateRoleSelectKeyFunction(Date.now) //re-render roles dropdowns but updating their key
//     }
//   }
// }

// const onTextInputChange = (updatedValue, updateFunction, storeName) => {
//   let newStringValue = updatedValue
//   if (typeof newStringValue !== 'string' || !(newStringValue instanceof String)) { // If only one value passed in
//     newStringValue = updatedValue.target.value
//   }

//   updateFunction(newStringValue)
//   addStringToStore(newStringValue, storeName)
// }

const SearchPage = ( { data, pageContext } ) => {
    const title = 'Search'

    // let allContactsData = pageContext.allContacts.edges
    let allContactsData = data.allContacts.edges
    const imagesData = pageContext.tileImagesData
    // const imagesData = keepOnlyFirstImageFromEachContact(allImagesData)

    //Filter to remove contacts with no images
    allContactsData = removeContactsWithNoImages(allContactsData, imagesData)

    return (
        
      <Layout>

        <Helmet>
          <title>{title}</title>
          <meta name='description' content='Search the Monarch People talent roster' />
        </Helmet>

        <div className="page-content search-page">
        
          <title>{title}</title>
          <h1>{title}</h1>

          <ProfileGrid contactsData={allContactsData} imagesData={imagesData} showAdvancedFilters={true} />

        </div>
        
      </Layout>
    )
  }

export default SearchPage

export const query = graphql`
  query {
    allPrismicSearch {
        nodes {
            data {
                body_content {
                  html
                }
            }
        }
    }
    allContacts (
      filter: {
        monarchStatus: {
          eq: "Active"
        }
      }
      sort: { 
        order: [ASC, ASC],
        fields: [sortNumber, firstname]
      }
    )
    {
      edges {
        node {
          id
          firstname
          lastname
          fullname
          dateOfBirth
          genders
          sortNumber
          categories
          modelSubcategories
          talentSubcategories
          experience {
            training
          }
          skills {
            accentSkills
            artisticSkills
            athleteSkills
            circusSkills
            combatAndStuntsSkills
            danceSkills
            drivingSkills
            flyingSkills
            horseSkills
            languages
            miscSkillsAttributes
            musicAndSingingSkills
            otherSkills
            otherSportsSkills
            voiceoverMcPresentingSkills
            waterSportsSkills
            winterSportsSkills
          }
          measurements {
            chestBust
            height
            hip
            jeanSize
            kidsClothingSize
            shirtTopSize
            shoeSize
            waist
            seat
            head
          }
          info {
            city
            ethnicity
            closestCentre
            eyeColour
            hairColour
            nationality
            nativeLanguage
            naturalAccent
            adminNotes
          }
          personalInfo {
            driverLicenceType
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

// export const query = graphql`
//   query {
//     allPrismicSearch {
//         nodes {
//             data {
//                 body_content {
//                   html
//                 }
//             }
//         }
//     }
//     allContacts (
//       filter: {
//         monarchStatus: {
//           eq: "Active"
//         }
//       }
//       sort: { 
//         order: [ASC, ASC],
//         fields: [sortNumber, firstname]
//       }
//     )
//     {
//       edges {
//         node {
//           id
//           firstname
//           lastname
//           fullname
//           dateOfBirth
//           genders
//           sortNumber
//           categories
//           modelSubcategories
//           talentSubcategories
//           experience {
//             training
//           }
//           skills {
//             accentSkills
//             artisticSkills
//             athleteSkills
//             circusSkills
//             combatAndStuntsSkills
//             danceSkills
//             drivingSkills
//             flyingSkills
//             horseSkills
//             languages
//             miscSkillsAttributes
//             musicAndSingingSkills
//             otherSkills
//             otherSportsSkills
//             voiceoverMcPresentingSkills
//             waterSportsSkills
//             winterSportsSkills
//           }
//           measurements {
//             chestBust
//             height
//             hip
//             jeanSize
//             kidsClothingSize
//             shirtTopSize
//             shoeSize
//             waist
//             seat
//             head
//           }
//           info {
//             city
//             ethnicity
//             closestCentre
//             eyeColour
//             hairColour
//             nationality
//             nativeLanguage
//             naturalAccent
//           }
//           personalInfo {
//             driverLicenceType
//           }
//           fields {
//             slug
//           }
//         }
//       }
//     }
//     images: allS3Object {
//       nodes {
//         Key
//         localFile  {
//           childImageSharp {
//             gatsbyImageData(transformOptions: {fit: COVER, cropFocus: ATTENTION})
//           }
//         }
//       }
//     }
//   }
// `
